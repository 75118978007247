import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import styled from 'styled-components';
import Body from '../components/Body';
import Layout from '../components/Layout';

const FourStarsOutOfFour: FC = () => {
    const { locale } = useIntl();

    return (
        <Layout showFooter>
            <Container>
                <Body>
                    <FormattedMessage
                        defaultMessage="Deze pagina bestaat niet. Ga naar de {homepage} of duik het {archive} in."
                        id="fourohfour"
                        values={{
                            homepage: (
                                <StyledLink to={`/${locale}`}>
                                    <FormattedMessage
                                        defaultMessage="homepagina"
                                        id="homepage"
                                    />
                                </StyledLink>
                            ),
                            archive: (
                                <StyledLink to={`/${locale}/archive-overview`}>
                                    <FormattedMessage
                                        defaultMessage="archief"
                                        id="archive"
                                    />
                                </StyledLink>
                            ),
                        }}
                    />
                </Body>
            </Container>
        </Layout>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 80px;
    margin-top: 50px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        margin-top: 180px;
        align-items: stretch;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        margin-top: 200px;
    }
`;

const StyledLink = styled(Link)`
    border-bottom: 3px solid ${({ theme }) => theme.colors.domas3};
`;

export default FourStarsOutOfFour;
